<template>
<div class="light-container">
  <!-- <div class="power-areas">
    <CommonTree />
  </div> -->
  <div class="map-content">
    <MapIndex />
  </div>
</div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
// import CommonTree from '../common/treeGroup/Index.vue'
import MapIndex from './mapNew/Index'
export default {
  name: 'Home',
  components: {
    MapIndex,
    // CommonTree,
  },
  data(){
    return {
    }
  },
  watch: {
  },
  created: function(){
    this.$store.commit('auth/init');
    // console.log('gis page created');
  },
  mounted: function(){
    // console.log('gis page mounted', this.ops);
  },
  destroyed: function(){
  },
  computed: {
    ...mapGetters('auth', ['custAndType', 'showLang', 'funCodes']),
    ...mapState('auth', ['ops', 'user', 'customerId', 'customers', 'appTypes', 'config','langs', 'appType', 'agentChangeValue']),
  },
  methods: {
  }
}
</script>
<style scoped>
.power-areas{
  /* border: solid 1px red; */
  width: 300px;
  margin: 0;
  flex: none;
  /* background-color: #001F37; */
  display: flex;
  flex-direction: column;
}
.light-container{
  /* border: solid 1px red; */
  height: 100%;
  background: #E3E9EF;
  display: flex;
  width: 100%;
  align-items: stretch;
}
.map-content{
  /* border: solid 1px red; */
  width: 300px;
  flex: auto;
}
</style>