<template>
  <div class="mapPolice-stat">
    <div class="mapPolice-title"><i></i>设备状态</div>
    <div class="tabList">
      <div class="tabList-box-top">
        <div class="lampName">设备</div>
        <div class="tabList-name">报警类型</div>
        <div class="startTime">时间</div>
      </div>
      <div class="tabList-box" v-for="el,index in list" :key="index">
        <div class="lampName" :title="el.lampName">{{el.lampName}}</div>
        <div class="tabList-name">
          <span>{{el.name}}</span>
        </div>
        <div class="startTime">{{el.startTime}}</div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  components: {
  },
  props: {
    value: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      list: []
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes', 'showLang']),
    ...mapState('common', ['powerBoxAlarmTypes', 'productCodes']),
    ...mapState('group', ['groups', 'selectedDevices', 'groupTreeSelectedNode']),
  },
  watch: {
    // position: {
    //   handler() {
    //     this.$refs[`customOverlay${this.sample.name}`].reload()  // 当位置发生变化时，重新渲染，内部会调用draw
    //   },
    //   deep: true
    // }
  },
  mounted: function () {
    this.init()
  },
  methods: {
    init() {
      this.$axios.post(`device/light/QueryCurrentAlarm`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this, 'list', res.data.list);
          // this.list = [
          //   { lampName: 'lampName', name: '报警', startTime: '2024-03-18 20:00:06' },
          //   { lampName: 'lampName1', name: '报警', startTime: '2024-03-18 20:00:06' },
          //   { lampName: 'lampName2', name: '报警', startTime: '2024-03-18 20:00:06' },
          //   { lampName: 'lampName3', name: '报警', startTime: '2024-03-18 20:00:06' },
          //   { lampName: 'lampName4', name: '报警', startTime: '2024-03-18 20:00:06' },
          //   { lampName: 'lampName5', name: '报警', startTime: '2024-03-18 20:00:06' },
          // ]
        }
      });
    }
  }
}
</script>

<style>
.mapPolice-stat {
  width: 100%;
  height: 100%;
  background: #ffffff;
  border-radius: 6px;
  padding: 24px 24px 24px 40px;
}
.mapPolice-title {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 20px;
  color: #333333;
  position: relative;
  height: 30px;
  margin-bottom: 10px;
}
.mapPolice-title i {
  position: absolute;
  width: 3px;
  height: 17px;
  background: #3379fb;
  left: -15px;
  top: 8px;
}
.tabList {
  height: calc(100% - 24px);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}
.tabList-box-top {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 8px;
  border-bottom: 1px solid #f1f5f8;
}
.tabList-box {
  display: flex;
  justify-content: space-between;
  height: 42px;
  line-height: 42px;
}
.tabList-box:nth-child(2n) {
  border-bottom: 1px solid #f7fafe;
  background: #f7fafe;
}
.tabList-box:nth-child(2n-1) {
  background: #fff;
}
.tabList-name span {
  background: #fde9ed;
  font-family: Source Han Sans CN;
  font-weight: 500;
  font-size: 14px;
  color: #f53a65;
  display: inline-block;
  padding: 0 5px;
  min-width: 72px;
  border-radius: 13px;
  text-align: center;
  height: 27px;
  line-height: 27px;
}
</style>